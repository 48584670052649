<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :isFilter="false" :title="$t('images')">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :isFilter="false"
                    :title="$t('images')"
                >
                </HeaderMobile>
            </template>
            <b-row>
                <b-col cols="8" md="6" lg="4">
                    <h5 class="ml-2 mb-3">{{ $t("select_image_upload") }}</h5>
                    <parameter-selectbox
                        v-model="image_type"
                        code="logo_types"
                    />
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="12" md="8" lg="6">
                    <ImageCard v-if="image_type" v-model="images" :image_type="image_type" />
                </b-col>
            </b-row>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import SystemLogoService from "@/services/SystemLogoService";

export default {
    name: "SystemImages",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        ImageCard: () => import("../components/ImageCard"),
    },
    metaInfo() {
        return {
            title: this.$t("images"),
        };
    },
    data() {
        return {
            image_type: null,
            images: {},
        };
    },
    methods: {},
    async created() {
        const response = await SystemLogoService.show();
        this.images = response.data;
    },
};
</script>
